import { useEffect, useState } from 'react'
import { PostgrestError } from 'react-supabase'
import { builder, FilterBuilder } from 'src/hooks/supabase/services'
import { supabaseClient } from 'src/utils/supabase'

export function useSelectOne<Data>(
  table: string,
  config: {
    columns: string
    filter: (builder: FilterBuilder) => FilterBuilder
  }
) {
  const [fetching, setFetching] = useState<boolean>(false)
  const [error, setError] = useState<PostgrestError>()
  const [data, setData] = useState<Data | undefined>(undefined)

  useEffect(() => {
    ;(async () => {
      setFetching(true)
      const filters = config.filter(builder)?.filters
      const query = supabaseClient.from(table).select(config.columns)
      filters?.forEach((filter) => {
        query.filter(filter.column, filter.operator, filter.value)
      })
      const { data, error } = await query.single()
      if (error) {
        setError(error)
        setData(undefined)
        setFetching(false)
      }
      if (data) {
        setData(data)
        setFetching(false)
      }
    })()
  }, [])

  return [{ data, fetching, error }]
}
