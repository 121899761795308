import {useEffect, useState} from "react";

export interface UseEmailValidatorType {
  value: string
  error: string
  valid: boolean
  setValue: (email: string) => void
}

const useEmailValidator = () => {
  const [value, setValue] = useState<string>('')
  const [error, setError] = useState<string>('')
  const [valid, setValid] = useState<boolean>(false)


  useEffect(() => {
    if (value.trim().length === 0) {
      setValid(false)
      setError('')
    } else if (!isEmail()) {
      setError("Vous devez saisir une adresse mail valide !")
      setValid(false)
    } else {
      setValid(true)
      setError('')
    }
  }, [value])


  const setEmail = (email: string) => setValue(email)
  const isEmail = () => /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(value)

  return [
    error,
    valid,
    value,
    setEmail,
  ] as unknown as UseEmailValidatorType
}

export {
  useEmailValidator
}
