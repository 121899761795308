import {Filter} from "src/hooks/supabase/types";

export class FilterBuilder {
  private _filters: Filter[] = []

  public add(filter: Filter) {
    this._filters.push(filter)
    return this
  }

  public get filters() {
    return this._filters
  }
}

export const builder = new FilterBuilder()
