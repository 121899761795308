import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material'
import { Link } from 'gatsby-material-ui-components'
import * as React from 'react'
import { Layout } from 'src/components/organisms/layouts'
import { useAuth } from 'src/hooks'

export default function Index() {
  const { user } = useAuth()

  return (
    <Layout>
      {!user ? (
        <div>
          <div>you are not logged in</div>
          <Link to="/sign-in-with-magick-link/">Sign in with magic link</Link>
          {/*<Redirect to="/sign-in-with-magick-link/" noThrow />*/}
        </div>
      ) : (
        <>
          <Box sx={{ typography: 'h3' }} mt={4}>
            Fonctionnalités
          </Box>

          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Fonctionnalité</TableCell>
                  <TableCell>Description</TableCell>
                  <TableCell>A faire</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {/*row 1*/}
                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell component="th" scope="row">
                    <Link to="/my-list-without-authentication/">
                      Ma liste sans authentification
                    </Link>
                  </TableCell>
                  <TableCell>les données proviennent de l'API. Aucune authentication</TableCell>
                  <TableCell>ok</TableCell>
                </TableRow>

                {/*row 2*/}
                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell component="th" scope="row">
                    <Link to="/sign-in-with-magick-link/">Login avec un lien "magique"</Link>
                  </TableCell>
                  <TableCell>
                    Un email est généré qui contient un lien magique qui permet à l'utilistauer de
                    s'authentifier pendant 1 heure
                  </TableCell>
                  <TableCell>
                    <ul>
                      <li>ok</li>
                    </ul>
                  </TableCell>
                </TableRow>

                {/*row 3*/}
                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell component="th" scope="row">
                    <Box>
                      <Link to="/my-profile/">Mon profil</Link>
                    </Box>
                  </TableCell>
                  <TableCell>Gestion du profil</TableCell>
                  <TableCell>ok </TableCell>
                </TableRow>

                {/*row 4*/}
                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell component="th" scope="row">
                    <Link to="/my-list-without-authentication/">Ma liste privée</Link>
                  </TableCell>
                  <TableCell>Affichage d'une liste personelle Authentification requise.</TableCell>
                  <TableCell>ok</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
    </Layout>
  )
}
