import React, { useEffect,useState } from 'react'
export function useDebounce<T>(value: T, delay?: number): { debouncedValue: [T, React.Dispatch<React.SetStateAction<T>>][0] } {
  const [debouncedValue, setDebouncedValue] = useState<T>(value)

  useEffect(() => {
    const timer = setTimeout(() => setDebouncedValue(value), delay || 500)

    return () => {
      clearTimeout(timer)
    }
  }, [value, delay])

  return { debouncedValue }
}

export default useDebounce
